import "./NotFoundPage.scss";

function NotFoundPage() {

    return (
        <main>
            <p>NotFoundPage</p>
        </main>
    )
}

export default NotFoundPage;